import React from 'react';
import * as styles from './Accordion.module.css';

export default function Accordion(props) {

    return (
        <div className={styles.accordionContainer}>
            <p className={styles.accordionToggle} onClick={e => props.toggle(props.title)}>
                {props.title}
            </p>
            <div 
                className={styles.accordionContent} 
                style={{
                    display: props.displayed ? '' : 'none'
                }}
            >
                <p>
                    {props.content}
                </p>
            </div>
        </div>
    )
}