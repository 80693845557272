import React, { useState } from 'react';
import Accordion from '../Accordion/Accordion';
import * as styles from './TabContainer.module.css';

export default function TabContainer(props) {
    const [content, setContent] = useState(props.content.map(a => {
        const {title, content} = a
        return {
            title,
            content,
            displayed: false
        }
    }))

    const toggleAccordions = (current_title) => {
        setContent(content.map(a => {
            if (a.title === current_title) {
                return {...a, displayed: !a.displayed}
            } else {
                return {...a, displayed: false}
            }
        }))
    }

    const accordions = content.map((a, i) => {
        return (
            <Accordion 
                key={i} 
                title={a.title} 
                content={a.content}
                displayed={a.displayed}
                toggle={toggleAccordions} 
            />
        )
    })

    return (
        <div className={styles.tabContainer} id={props.id} style={{display: props.displayed ? "" : "none"}}>
            <div id={`accordion-${props.accordion_id}`}>
                {accordions}
            </div>
        </div>
    )
}