import React from 'react';
import paraDevices from '../../../images/para-devices.jpg';
import paraDevices_2x from '../../../images/para-devices@2x.jpg';
import * as styles from './DevicesSection.module.css';

export default function DevicesSection() {
    return (
        <div className={styles.section}>
            <div className="container section-container">
                <img
                    src={paraDevices}
                    srcSet={`${paraDevices_2x} 2x`}
                    alt="Para on tablet and smartphone"
                />
            </div>
        </div>
    )
}