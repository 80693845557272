import React from "react";
import { Helmet } from "react-helmet";
import Landing from "../components/Landing";
import "../styles/global.css";

const Index = ({ data }) => {
  return (
    <>
      <Helmet>
        <title>
          Para | Connecting Per Diem Nurses With Vacant Healthcare Shifts
        </title>
        <meta
          name="description"
          content="The Para app connects nurses looking for per diem nursing shifts with healthcare facilities that need to fill vacant shifts quickly. Sign up today!"
        />
        <link rel="canonical" href="https://joinpara.com/" />
      </Helmet>
      <Landing />
    </>
  );
};

export default Index;
