import React from 'react';
import * as styles from './FeaturesSection.module.css';
import para1 from '../../../images/para-1.jpg';
import para1_2x from '../../../images/para-1@2x.jpg';
import para2 from '../../../images/para-2.jpg';
import para2_2x from '../../../images/para-2@2x.jpg';
import para3 from '../../../images/para-3.jpg';
import para3_2x from '../../../images/para-3@2x.jpg';
import para4 from '../../../images/para-4.jpg';
import para4_2x from '../../../images/para-4@2x.jpg';
import para5 from '../../../images/para-5.jpg';
import para5_2x from '../../../images/para-5@2x.jpg';

export default function FeaturesSection() {
    return (
        <div className={styles.section}>
            <div className={`${styles.container} ${styles.hire} container section-container`} id="hire">
                <div className={styles.widthHalf}>
                    <h3>Post shifts that meet your needs</h3>
                    <p>
                        Para matches high quality professionals to your posted shifts.
                    </p>
                </div>
                <div className={styles.widthHalf}>
                    <img src={para1} srcSet={`${para1_2x} 2x`} alt="Map of available shifts"/>
                </div>
            </div>
            <div className={`${styles.container} container section-container`} >
                <div className={styles.widthHalf}>
                    <h3>Just easy</h3>
                    <p>
                        Automatic screening, matching, and hassle free payments.<br /><strong
                        >No annual fees or commitments.</strong
                        >
                    </p>
                </div>
                <div className={styles.widthHalf}>
                    <img src={para2} srcSet={`${para2_2x} 2x`} alt="Analyzing potential nursing staff"/>
                </div>
            </div>
            <div className={`${styles.container} ${styles.work} container section-container`} id="work">
                <div className={styles.widthHalf}>
                    <h3>No recruiters, no paperwork</h3>
                    <p>Create a profile and Para will bring shifts to you.</p>
                </div>
                <div className={styles.widthHalf}>
                    <img src={para3} srcSet={`${para3_2x} 2x`} alt="Graphic of app"/>
                </div>
            </div>
            <div className={`${styles.container} container section-container`}>
                <div className={styles.widthHalf}>
                    <h3>Shifts you want, when you want them</h3>
                    <p>...Simple as that.</p>
                </div>
                <div className={styles.widthHalf}>
                    <img src={para4} srcSet={`${para4_2x} 2x`} alt="Graphic of someone viewing app on couch"/>
                </div>
            </div>
            <div className={`${styles.container} container section-container`}>
                <div className={styles.widthHalf}>
                    <h3>
                        No More Agencies<br />No More Missed Opportunities<br />No More
                        Compromises
                    </h3>
                </div>
                <div className={styles.widthHalf}>
                    <img src={para5} srcSet={`${para5_2x} 2x`} alt="Graph of Para's better savings than competition"/>
                </div>
            </div>
        </div>
    )
}