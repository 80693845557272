import React from 'react';
import WelcomeSection from '../sections/WelcomeSection/WelcomeSection';
import VideoSection from '../sections/VideoSection/VideoSection';
import FeaturesSection from '../sections/FeaturesSection/FeaturesSection';
import DevicesSection from '../sections/DevicesSection/DevicesSection';
import FAQSection from '../sections/FAQSection/FAQSection';
import './LandingMain.css';

export default function Main() {
    return (
      <div className="main">
        <WelcomeSection />
        <VideoSection />
        <FeaturesSection />
        <DevicesSection />
        <FAQSection />
      </div>
    )
}