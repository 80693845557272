import React, { useRef } from 'react';
import videoPoster from '../../../images/video-poster.jpg';
import paraPromo from '../../../videos/para-promo.mp4';
import * as styles from './VideoSection.module.css';

export default function VideoSection() {
    const vidRef = useRef(null)
    const playPauseRef = useRef(null)
    const playPause = () => {
        if (vidRef.current.paused) {
            vidRef.current.play()
            playPauseRef.current.className = `${styles.playpause} ${styles.fadeOut}`
        } else {
            vidRef.current.pause()
            playPauseRef.current.className = styles.playpause
        }
        
    }

    return (
        <div className={styles.section}>
            <div className="container">
                <h2 className="text-center">Here's how it works</h2>
                <div className={styles.videoContainer} onClick={playPause}>
                    <video ref={vidRef} poster={videoPoster} className={styles.video}>
                        <source src={paraPromo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div ref={playPauseRef} className={styles.playpause}></div>
                </div>
            </div>
        </div>
    )
}