import React, { useState } from 'react';
import { professionals, facilities } from '../../../faq-store';
import TabContainer from '../../TabContainer/TabContainer';
import * as styles from './FAQSection.module.css';

export default function FAQSection() {
    const [tab_selectors, setSelectors] = useState([
        {
            id: 'tab1',
            label: 'For Professionals',
            inactive: false
        },
        {
            id: 'tab2',
            label: 'For Facilities',
            inactive: true
        }
    ])

    const [tab_groups, setGroups] = useState([
        {
            id: 'tab1C',
            displayed: true,
            accordion_id: 'professionals',
            content: professionals
        },
        {
            id: 'tab2C',
            displayed: false,
            accordion_id: 'facilities',
            content: facilities
        }
    ])

    const toggleTabs = (tab) => {

        setSelectors(tab_selectors.map(t => {
            if (t.id === tab.id) {
                return {...t, inactive: false}
            } else {
                return {...t, inactive: true}
            }
            
        }))

        setGroups(tab_groups.map(t => {
            if (t.id.includes(tab.id)) {
                return {...t, displayed: true}
            } else {
                return {...t, displayed: false}
            }
            
        }))
    }

    const tabs = tab_selectors.map((t, i) => {
        return (
            <li key={i}>
                <button
                    id={t.id} 
                    className={t.inactive ? styles.inactive : ''} 
                    onClick={e => toggleTabs(e.target)}
                >
                    {t.label}
                </button>
            </li>
        )
    })

    const tab_containers = tab_groups.map((t, i) => {
        const {id, displayed, accordion_id, content} = t
        return (
            <TabContainer
                key={i} 
                id={id}
                displayed={displayed}
                accordion_id={accordion_id}
                content={content}
            />
        )
    })

    return (
        <div className={styles.section}>
            <div className="container">
                <div id="faq" className={styles.faq}>
                    <h2 className="text-center">Frequently asked questions</h2>
                    <ul id="tabs" className={styles.tabs}>
                        {tabs}
                    </ul>
                </div>
                {tab_containers}
            </div>
        </div>
    )
}