export const professionals = [
    {
        title: `Is the Para app for full time employment?`,
        content: `No, the Para app is not for full time employment. We are a
            marketplace for you to find additional work as an
            independent contractor. As an independent contractor you can
            receive and accept shifts in your area through the Para
            platform.`  
    },
    {
        title: `Where are you operating? Is the Para app free to use?`,
        content: `Currently, we are servicing the Chicagoland and Northwest
            Indiana area. But shoot us a message, we'd love to hear from
            you if you’re interested in Para; we're always looking to
            grow into our next market.`  
    },
    {
        title: `Yes the app is and will always be free to use for medical
            professionals . How many shifts do I have work per month?`,
        content: `After you're oriented at a facility, there are no monthly
            shifts requirements, simply work when you want.`  
    },
    {
        title: `How does orientation work with different facilities?`,
        content: `Orientation varies at each facility although Para offers
            flexible and creative solutions to have your orientation
            completed at a range of facilities without all the hassle.`  
    },
    {
        title: `How do my credentials get verified for each healthcare
            facility?`,
        content: `No more paperwork! All of your credentials and
            certifications will be loaded onto the app and the
            facilities will access them there. When you update your
            certifications you only need to load your new certificate to
            the app. Para will do the rest!`  
    },
    {
        title: `So on Para working as a independent contractor, do I have to
            pay taxes?`,
        content: `Most likely, while Para does not provide any personalize
            tax, investment, legal, or other business advice, Intuit
            provides some great resources to make things simple and plan
            ahead.`  
    },
    {
        title: `Am I allowed to pick up shifts at my current healthcare
            facility?`,
        content: `Unfortunately no, you can not use Para to work for your
            current place of employment or any affiliate of your current
            employer.`  
    },
]

export const facilities = [
    {
        title: `Is this like an agency, how does it work?`,
        content: `No not all - that's probably a sigh of relief. Post your
            shifts specifying the date, time, location and credential
            requirements and the Para platform matches highly qualified
            medical professionals working as independent contractors to
            fill your shifts, simple as that.`
    },
    {
        title: `Are there monthly fees or shift minimums?`,
        content: `There are no commitments, none! Use the Para platform as
            much or as little as you want, there are no long term
            contracts or subscription fees.`
    },
    {
        title: `Where are you operating?`,
        content: `Currently, we are servicing the Chicagoland and Northwest
            Indiana area. But shoot us a message, we'd love to hear from
            you if you’re interested in Para; we're always looking to
            grow into our next market.`
    },
    {
        title: `Do I have to install anything?`,
        content: `There is nothing to install; access your account securely
            and simply from any web browser.`
    },
    {
        title: `Once I post a shift, then what do I have to do?`,
        content: `Nothing, no really nothing. Para’s platform is highly
            automated,handling the process from start to finish. If you
            need to, feel free to review electronic timesheets, a
            medical professional’s profile, or download any record you’d
            like.`
    },
    {
        title: `So the medical professional is qualified, how do I know if
            they're “good” or will even show up?`,
        content: `While no process involving humans is perfect, Para’s quality
            algorithm receives ratings from facilities like you about
            medical professionals and ensures only “good” professionals
            stay on the platform to fill shifts.`
    },
    {
        title: `How do you handle orientation?`,
        content: `Para offers flexible and creative solutions to partner with
            you and minimize the cost in training new staff. The feeling
            of training someone and never seeing them again, will be a
            feeling of the past. Contact us for more information.`
    },
    {
        title: `Why should I use Para?`,
        content: `Simply put, Para is a software platform that arms hospitals
            with the ability to fill shift vacancies with the available
            time of highly qualified medical professionals as
            independent contractors. This breaks the mold of traditional
            solutions that are rigid and costly and provides the
            flexibility to meet the demand of your operations at a
            fraction of the cost.`
    },
]