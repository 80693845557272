import React from "react";
import * as styles from "./WelcomeSection.module.css";

export default function WelcomeSection() {
  return (
    <>
      <section className={styles.heroSection}>
        <div className="container" style={{ textAlign: "center" }}>
          <h1 className={styles.heroTitle}>Per Diem Nursing Jobs Made Easy</h1>
        </div>
      </section>
      <div className={styles.section}>
        <div className={`${styles.left} text-center`}>
          <div className={styles.titleContainer}>
            <h2 className={styles.white}>
              Fill vacant shifts
              <br />
              and save money.
            </h2>
            <a className={styles.btnPrimary} href="#hire">
              Hire
            </a>
          </div>
        </div>
        <div className={`${styles.right} text-center`}>
          <div className={styles.titleContainer}>
            <h2>
              Find extra shifts
              <br />
              and earn money.
            </h2>
            <a className={styles.btnSecondary} href="#work">
              Work
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
