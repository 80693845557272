import React from "react";
import Header from "./Header/Header";
import Main from "./LandingMain/LandingMain";
import Footer from "./Footer/Footer";

export default function Landing() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}
